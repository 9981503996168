@use './variables' as *;

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;

  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-fill-unstyled: #262626;
  --color-icon-fill-secondary: #{$color-verisk-white};
  --color-icon-fill-tertiary: #{$color-verisk-ext-blue-1};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-secondary: #{$color-verisk-white};
  --color-icon-background-primary: #{$color-verisk-action-blue-10};

  // Checkbox and text alignment default values
  --label-line-height: #{$spacing-3};
  --checkbox-height: #{$spacing-2};
  --z-index-dropdown: #{$z-index-dropdown};
}

.ngb-tp-input-container {
  width: 8em !important;
}
