@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/placeholders/border' as *;
@use 'libs/styling/src/lib/placeholders/icons' as *;
@use 'libs/styling/src/lib/placeholders/layout' as *;

// CURSOR
%pointer {
  cursor: pointer;
}

%checkbox-indeterminate {
  @extend %border-none;

  background: url('#{$img-icon-path}/check-intermediate.svg') center center no-repeat $color-label-grey;
  background-image: url(#{$img-icon-path}/check-intermediate.svg);
  background-size: auto 125%;
}

%checkbox-active {
  @extend %border-none;

  background: url('#{$img-icon-path}/check-tick-white.svg') center center no-repeat $color-verisk-action-blue;
  background-image: url(#{$img-icon-path}/check-box-tick.svg);
  background-size: auto 125%;
}

// CHECKBOXES
%checkbox {
  @extend %border;
  @extend %static;
  @extend %content;
  @extend %pointer;

  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  background-color: $color-verisk-white;
  border-radius: $border-radius-checkbox;

  &.checked,
  &:active {
    @extend %checkbox-active;
  }

  &:hover {
    border-color: $color-verisk-action-blue;
  }
}

%checkbox-small {
  @extend %checkbox;

  height: $spacing-2;
  width: $spacing-2;
  min-width: $spacing-2;
}

%checkbox-before {
  @extend %flex;
  @extend %margin-0;

  &::before {
    @extend %checkbox;

    margin-top: calc((var(--label-line-height) - var(--checkbox-height)) / 2);
    margin-right: $margin-1;
  }
}

%checkbox-before-small {
  @extend %flex;
  @extend %margin-0;

  &::before {
    @extend %checkbox-small;

    margin-top: calc((var(--label-line-height) - var(--checkbox-height)) / 2);
    margin-right: $margin-1;
  }
}

// TOGGLE SWITCHES
%toggle-switch-button {
  @extend %icon-size-2;
  @extend %border;
  @extend %content;
  @extend %absolute;
  @extend %top-0;

  background: $color-verisk-action-blue;
  border-radius: $toggle-button-size;
  border-color: $color-verisk-action-blue;
  right: calc(100% - #{$toggle-button-size});
  transition: right $transition-time $transition-function;
}

%toggle-switch-background {
  @extend %border;
  @extend %absolute;
  @extend %content;

  background: $color-verisk-white;
  border-radius: $spacing-1;
  height: $toggle-button-size;
  width: $toggle-background-width;
}

%toggle-switch-button-checked {
  @extend %right-0;

  background: $color-verisk-white;
}

%toggle-switch-background-checked {
  background: $color-verisk-action-blue;
  border-color: $color-verisk-action-blue;
}

%toggle-switch {
  @extend %relative;
  @extend %border-none;

  height: $toggle-button-size;
  width: $toggle-background-width;

  &::before {
    @extend %toggle-switch-background;
  }

  &:checked::before {
    @extend %toggle-switch-background-checked;
  }

  &::after {
    @extend %toggle-switch-button;
  }

  &:checked::after {
    @extend %toggle-switch-button-checked;
  }
}

// BUTTONS
%button-height {
  height: $button-size;
}

%button-square-size {
  @extend %button-height;

  width: $button-size;
  min-width: $button-size;
}
