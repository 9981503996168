@use 'mixins' as mixins;
@use 'placeholders/layout' as *;
@use 'placeholders/icons' as *;
@use 'placeholders/border' as *;
@use 'placeholders/forms' as *;
@use 'libs/styling/src/lib/variables' as *;

$modal-header-min-height: $spacing-6;
$modal-footer-min-height: $spacing-10;
$modal-min-height: 0;
$modal-max-height: 90vh;

// Overrides and additional styles for Maplecroft themed modals
// which cannot be achieved with simple theming support.
// Other Modal-related settings use theming support, see _bootstrap.scss

.modal-backdrop,
.modal {
  z-index: 1055;
}

// Class to wrap modal components
.modal-content {
  min-height: $modal-min-height;
  max-height: $modal-max-height;
  height: min-content;
  background-color: transparent;

  & > * {
    height: min-content;
  }
}

// Class to use inside modal components to wrap modal-header, modal-body and modal-footer
.modal-container {
  @extend %flex-column;
  @include mixins.border-radius($border-radius);

  background-color: $color-verisk-white;
  width: 100%;
  height: min-content;
  max-height: $modal-max-height;
  min-height: $modal-min-height;

  .close-icon {
    @extend %icon-cross-size;
    @extend %icon-cross-color;
    @extend %inline-flex;
    @extend %pointer;

    margin-left: auto;

    &:hover {
      opacity: 0.7;
    }
  }

  .modal-header {
    min-height: $modal-header-min-height;
    padding: 0 1.6rem;
    align-items: center;
    background-color: $color-verisk-ext-blue-1;
    color: $color-white;
    font-size: $font-size-2;
    font-weight: $font-weight-medium;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;

    .modal-title {
      color: $color-white;
      margin-right: auto;
    }

    .close-icon {
      @extend %icon-cross-color-inverted;
    }
  }
}

// to override some styles for the close/dismiss button
// which should not be changed generally through theming support
// as theming would impact other potential uses (e.g. in alerts)

.modal-body {
  --modal-body-padding: 2.4rem;

  @extend %padding;

  background-color: $color-verisk-white;
  height: min-content;
  overflow-y: auto;

  .modal-body-title {
    margin-bottom: $margin-2;
  }

  .modal-body-header {
    display: flex;
    position: relative;
    height: 1.6rem;
    flex-shrink: 0;
    width: 100%;

    .close {
      position: absolute;
      margin: 0;
      top: calc(0% - var(--modal-body-padding));
      left: calc(100% + var(--modal-body-padding));
      transform: translateX(-200%) translateY(100%);
    }
  }
}

.modal-footer {
  min-height: $modal-footer-min-height;
  padding: 0 2.4rem 2.4rem;
  background-color: $color-verisk-white;
  border-top: 0;

  @include mixins.border-bottom-radius($border-radius);

  button,
  grid-ui-button {
    margin: 0 $margin-1 0 0;
  }

  button:last-child,
  grid-ui-button:last-child {
    margin-right: 0;
  }
}

.modal-dialog {
  align-items: center;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
}

.data-wizard-flow-guide-modal {
  @extend %absolute;

  overflow-y: hidden !important;

  .modal-dialog {
    top: 1rem;
    width: 400px;
    height: 230px;
  }

  .modal-content {
    @extend %absolute;

    &::before {
      --triangle-height: #{$spacing-2};
      --triangle-color: #{$color-verisk-white};

      @extend %absolute;
      @extend %content;
      @extend %horizontal-center;
      @extend %triangle-up;

      top: calc(-2 * var(--triangle-height));
    }
  }

  .modal-body {
    @extend %border-bottom;
  }

  .modal-footer {
    width: 100%;
    padding-bottom: 0;
  }
}

// Popover information modal
.modal.mc-popover-modal-custom-class {
  .modal-dialog {
    max-width: 700px;
  }
}
