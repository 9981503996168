@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/placeholders/layout' as *;

.expand-panel {
  border: 1px solid $color-maplecroft-light-grey;

  .expand-panel-header {
    @extend %padding;
    @extend %flex-row;
    @extend %align-center;

    padding-top: 0;
    padding-bottom: 0;
    min-height: $spacing-5;
    color: $color-verisk-ext-blue-1;
    border-bottom: 1px solid $color-maplecroft-light-grey;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .expand-panel-body {
    @extend %padding;

    background-color: $color-white;
  }
}
