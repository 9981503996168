@use '@ag-grid-community/styles' as ag;
@import 'libs/styling/src/lib/variables';

// customise bootstrap base colours
$theme-colors: (
  primary: $color-verisk-action-blue-10,
);

@import 'ol/ol.css';

.commodity {
  position: absolute;
  right: 0;
  left: 0;
  height: calc(100vh - 82px);
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;

  @include ag.grid-styles(
    (
      theme: custom-angular,
      extend-theme: balham,
      icon-font-family: agGridMaterial,
      grid-size: 3px,
      cell-widget-spacing: 2px,
      cell-horizontal-padding: 4px,
      // header-height: ag-derived(row-height, $times: 2),
      font-size: 1.1rem,
      font-family: unset,
      widget-container-horizontal-padding: 6px,
      widget-container-vertical-padding: 6px,
      widget-horizontal-spacing: 6px,
      widget-vertical-spacing: 6px,
    )
  );

  .ag-theme-custom-angular {
    // enforce text wrapping on table headers and cells
    .ag-header-cell-text {
      white-space: normal;
      text-align: center;
      line-height: 1.3rem;
      font-weight: 500 !important;
    }

    .ag-cell-wrap-text {
      word-break: break-word;
      line-height: 1.3rem;
    }

    .ag-tooltip {
      max-width: 250px;
      white-space: normal;
      word-break: break-word;
    }

    .cell-center-vertical {
      display: flex;
      align-items: center;
    }

    &.row-selection-basic {
      .ag-cell.not-selected,
      .ag-row {
        opacity: 0.35;
      }

      .ag-row-selected {
        background-color: inherit;
        opacity: 1;
      }
    }

    .ag-row-selected::before {
      display: none;
    }
  }

  .score-cell-default {
    color: black;
    font-weight: 500;
    text-align: center;
  }

  .score-cell-no-score {
    background-color: grey;
  }

  .score-cell-extreme {
    color: #e8e8e8;
    background-color: #ce181f;
  }

  .score-cell-high {
    background-color: #f47721;
  }

  .score-cell-medium {
    background-color: #ffc709;
  }

  .score-cell-low {
    background-color: #d6e040;
  }

  .map-passive-popups .ol-overlay-container {
    pointer-events: none !important;
  }

  .panel {
    margin: 0.3rem 0 0 0.3rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .panel-header {
      background-color: $color-verisk-ext-blue-1;
      color: $color-white;
      padding: 0.2rem 0.4rem;
    }

    .panel-header-controls {
      float: right;
      cursor: pointer;
      user-select: none;
    }
  }

  .panel-border {
    border-left: solid 1px $color-dark-grey;
    border-right: solid 1px $color-dark-grey;
    border-bottom: solid 1px $color-dark-grey;
  }

  .spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .legend {
    font-size: 1.12rem;
    width: 100%;
    border-top: $color-maplecroft-medium-grey 1px solid;
    text-align: end;
    padding-top: 2px;

    .score-label {
      display: inline-block;
      height: 19px;
      padding: 0 3px;
      border-radius: 3px;
      margin-left: 2px;
      margin-right: 10px;
      color: black;
    }

    .extreme {
      background-color: $color-extreme-risk;
      color: $color-white;
    }

    .high {
      background-color: $color-high-risk;
    }

    .medium {
      background-color: $color-medium-risk;
    }

    .low {
      background-color: $color-low-risk;
    }
  }
}
