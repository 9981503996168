@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/placeholders/forms' as *;

.ag-theme-datatable,
.ag-theme-scorecard {
  .ag-checkbox-input-wrapper {
    &:active,
    &:focus-within {
      box-shadow: none;
    }

    &::after {
      @extend %checkbox-small;

      content: '';
    }

    &.ag-checked::after {
      @extend %checkbox-small;
      @extend %checkbox-active;

      content: '';
    }
  }

  .ag-group-checkbox {
    width: $padding-3;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    border-top: 1px solid $color-verisk-ext-blue-1;
    border-bottom: 1px solid $color-verisk-ext-blue-1;
    background-color: transparent;
  }

  // enforce text wrapping on table headers and cells
  .ag-header-cell-text {
    white-space: normal;
    text-align: center;
    line-height: 1.3rem;
    font-weight: 500;
    font-size: 1.2rem;
    color: $color-verisk-ext-blue-1;
  }

  .ag-row,
  .ag-cell {
    border-top: none;
  }

  &.datatable-indicator-table {
    .ag-cell-wrapper {
      border-color: transparent !important;
    }
  }

  .ag-row {
    .ag-cell:first-child {
      border-right: 1px solid $color-maplecroft-light-grey;
    }

    .ag-cell-wrapper.ag-row-group-leaf-indent.ag-row-group-indent-0 {
      margin-left: 2.2rem;
    }

    .ag-cell-wrapper.ag-row-group-leaf-indent.ag-row-group-indent-1 {
      margin-left: 2.2rem;
    }

    .ag-row-group.ag-row-group-indent-1 {
      padding-left: 2.8rem;
    }

    .ag-row-group.ag-row-group-indent-2 {
      padding-left: 5.6rem;
    }

    .ag-row-group.ag-row-group-indent-3 {
      padding-left: 8.4rem;
    }
  }

  .ag-row-hover {
    background-color: $color-verisk-action-blue-10;
  }

  .ag-cell-wrap-text {
    word-break: break-word;
    line-height: 1.3rem;
  }
}

.ag-theme-datatable {
  .ag-header-cell {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .ag-row-group-expanded {
    .dt-group-expand-icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.ag-cell-wrapper.ag-row-group {
  align-items: center;
}

.header-roll-animations {
  .ag-header-cell {
    padding: 0;
  }

  .animated-header {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  &.roll-in-animation {
    .animated-header {
      animation:
        roll-in 0.4s,
        highlight-fade 2s;

      @keyframes roll-in {
        from {
          transform: translate(0, 3rem);
        }
        to {
          transform: translate(0, 0);
        }
      }

      @keyframes highlight-fade {
        from {
          background-color: $color-verisk-action-blue-10;
        }
        to {
          background-color: unset;
        }
      }
    }
  }

  &.roll-out-animation {
    .animated-header {
      animation: roll-out 0.4s;

      @keyframes roll-out {
        from {
          transform: translate(0, 0);
        }
        to {
          transform: translate(0, -3rem);
        }
      }
    }
  }
}
