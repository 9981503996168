/* stylelint-disable color-function-notation */
// Paths
$assets-path: '^assets';
$img-path: '#{$assets-path}/img';
$img-icon-path: '#{$img-path}/icons';
$img-reg-path: '#{$img-path}/registration';

// Colors
// @todo tidy this up. prob favour variables over maps.
// maybe have palette of colours plus set of context-specific variables
// which make it easier to theme
// e.g. $border-color: $color-grey;
//      $panel-background-color: $color-verisk-ext-blue-1;
//      $panel-background-color-active: $color-blue;
// i.e. $component-style-modifier
$color-alert-yellow: #ddd400;
$color-blue: #006ba6;
$color-verisk-action-blue: #2671cb;
$color-verisk-action-blue-10: #e8f0f9;
$color-verisk-action-blue-50: #93b8e5;
$color-verisk-globe-blue: #00358e;
$color-verisk-teal: #2ecddc;
$color-verisk-radical-blue: #2a7ed1;
$color-verisk-ext-blue-1: #002d61;
$color-verisk-ext-blue-2: #00629d;
$color-verisk-ext-blue-3: #047cb1;
$color-verisk-ext-blue-4: #4d9fd0;
$color-verisk-ext-blue-5: #a4cbf0;
$color-verisk-black: #000;
$color-verisk-black-85: #262626;
$color-verisk-black-75: #989595;
$color-verisk-black-55: #707071;
$color-verisk-black-50: #666;
$color-verisk-black-45: #8c8c8c;
$color-verisk-black-40: #999;
$color-verisk-black-25: #bfbfbf;
$color-verisk-black-20: #ccc;
$color-verisk-black-15: #d8d8d8;
$color-verisk-black-10: #dcdcdc;
$color-verisk-black-5: #f2f2f2;
$color-verisk-white: #fff;
$color-verisk-legend-extreme: #e53935;
$color-verisk-legend-high: #fb8c00;
$color-verisk-legend-medium: #fbc02d;
$color-verisk-legend-low: #cddc39;
$color-verisk-legend-neutral: $color-verisk-black-25;
$color-verisk-behavioural-gold: #ffc600;
$color-verisk-behavioural-red: #d40019;
$color-verisk-behavioural-green: #6abf4b;
$color-background-medium-blue: #caecff;
$color-grey: #54585a;
$color-crimson: #da291c;
$color-light-crimson: #faeaea;
$color-lime: #84bd00;
$color-verisk-grey: #54584a;
$color-label-grey: #999;
$color-dark-grey: #ccc;
$color-medium-grey: #eee;
$color-foreground-midnight: #295c7d;
$color-lake: #59cbe8;
$color-gold: #ffb100;
$color-low: #d6e040;
$color-medium: #ffc709;
$color-high: #f47721;
$color-extreme: #d0181f;
$color-no-data: #dadada;
$color-black: #333;
$color-white: #fff;
$color-maplecroft-light-grey: #dddddc;
$color-maplecroft-medium-grey: #979797;
$color-background-blue: #e3f5ff;
$color-background-red: #e42127;
$color-background-light-blue: #e5f6fc;
$color-table-border-grey: #e0e0e0;
$color-image-caption-grey: rgba(0, 0, 0, 50%);
$color-subscription-blue: #e5f0f6;
$color-input-placeholder: #6c757d;
$color-success: #6abf4b;
$color-error: #d40019;
$color-snow: #fafafa;
$color-light-grey: #ecf3f6;
$color-tint: #b3e5f6;
$color-maplecroft-green: #48af48;
$color-maplecroft-bright-blue: #22b8ef;
$color-maplecroft-bright-grey: #ddd;
$color-verisk-light-grey: #cccdce;
$color-verisk-mid-grey: #989b9c;
$color-verisk-dark-grey: #c2c2c2;
$color-background-grey: #f3f3f1;
$color-background-bright-blue: #cbedf9;
$color-low-risk: #cddc39;
$color-medium-risk: #fbc02d;
$color-high-risk: #fb8c00;
$color-extreme-risk: #e53935;
$color-filters-red: #ff0404;
$color-risk-score-extreme: #ce181f;
$color-risk-score-high: #f47721;
$color-risk-score-medium: #ffc709;
$color-risk-score-low: #d6e040;
$color-announcement-dot: #5b6060;
$color-notification-dot: #d92a1c;

// Box shadow
$color-verisk-box-shadow: #00000029;

$breadcrumb-color: $color-label-grey;
$breadcrumb-color-hover: $color-verisk-behavioural-gold;
$page-control-color: $color-label-grey;
$page-control-color-bold: $color-black;
$grid-modal-backdrop-bg: #000;
$grid-modal-backdrop-opacity: 0.5;

// Fonts
$font-family-1: 'Roboto', sans-serif;
$font-family-regular: 'Roboto', helvetica, arial, sans-serif;
$font-family-light: 'Roboto', helvetica, arial, sans-serif;
$font-family-bold: 'Roboto', helvetica, arial, sans-serif;

// Fonts weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-light-italic: 300 i;
$font-weight-regular-italic: 400 i;
$font-weight-bold-italic: 700 i;

// Font Sizes
$font-size-0: 1rem; // p
$font-size-1: 1.2rem; // h6
$font-size-2: 1.4rem; // h5
$font-size-3: 1.6rem; // h4
$font-size-4: 1.8rem; // h3
$font-size-5: 2rem; // h2
$font-size-7: 2.4rem; // h1

// line heights
// Using classes e.g. lh-16

// sizes
$line-height: 2rem;
$line-height-normal: 2rem;
$spacer: 1.4rem;

// widths
$landing-content-max-width: 1200px; // Global landing page
$center-content-max-width: 1400px;
$written-content-max-width: 1140px;
$written-content-and-side-bar-max-width: 1600px;
$industry-definitions-max-width: 70rem;

// Spacings
$spacing-1: 0.8rem;
$spacing-2: 1.6rem;
$spacing-3: 2.4rem;
$spacing-4: 3.2rem;
$spacing-5: 4rem;
$spacing-6: 4.8rem;
$spacing-8: 6.4rem;
$spacing-10: 8rem;

// margins
// $margin-0: 0rem;
$margin-1: $spacing-1;
$margin-2: $spacing-2;
$margin-3: $spacing-3;
$margin-4: $spacing-4;
$margin-5: $spacing-5;
$margin-6: $spacing-6;
$margin-8: $spacing-8;
$margin-10: $spacing-10;

// paddings
// $padding-0: 0rem;
$padding-1: $spacing-1;
$padding-2: $spacing-2;
$padding-3: $spacing-3;
$padding-4: $spacing-4;
$padding-5: $spacing-5;
$padding-6: $spacing-6;
$padding-8: $spacing-8;
$padding-10: $spacing-10;

// borders
$border-width: 0.1rem;
$border-radius: 0.4rem;
$border-radius-checkbox: 0.2rem;

// Scrollbar
$scrollbar-width: 1rem;
$scrollbar-border-radius: $border-radius;
$scrollbar-background-color: $color-verisk-black-25;

// site nav elements
$nav-link-height: 4rem;
$side-nav-width: 4rem;
$loading-bar-height: 4rem;

// Side Panel
$side-panel-width: 46rem;

// Nav tabs
$tab-height: $spacing-4;

// breadcrumb area
$breadcrumb-height: 4rem;

// analyse toolbar
$analyse-toolbar-height: 6.8rem;

// Main content
$main-content-height: calc(100vh - $breadcrumb-height - $nav-link-height);

// Screen width breakpoints for card treatment
$screen-1400-min: 1400px;
$screen-1600-min: 1600px;
$screen-1800-min: 1800px;
$screen-2000-min: 2000px;

// Breakpoints
$breakpoint-xs-lower: 0;
$breakpoint-sm-lower: 479px;
$breakpoint-md-lower: 767px;
$breakpoint-lg-lower: 1023px;
$breakpoint-xl-lower: 1279px;

// Bootstrap Breakpoints Map
$grid-breakpoints: (
  xs: $breakpoint-xs-lower,
  sm: $breakpoint-sm-lower,
  md: $breakpoint-md-lower,
  lg: $breakpoint-lg-lower,
  xl: $breakpoint-xl-lower,
);

// Cards
$card-width: 24.8rem;
$card-margin: 2rem;

// Forms
$toggle-background-width: 2.8rem;
$toggle-button-size: $spacing-2;
$button-size: 3.6rem;

// Transitions
$transition-time: 0.3s;
$transition-function: cubic-bezier(0.22, 0.61, 0.36, 1);

// z-index
// bootstrap modal default z-index is 2000
// bootstrap tooltip default z-index is 1070
$z-index-tooltip: 2001;
$z-index-dropdown: 2001;
$z-index-modal: 2000;
$z-index-modal-background-overlay: 1999;
$z-index-modal-cross: 2001;

// Embedded HTML content
$embedded-content-spacing: 1.5em;

// Variable for the Safari-specific media query hack
$safari-webkit-hack: 0.001dpcm;
