@use 'libs/styling/src/lib/variables' as *;

%border-top {
  border-top: $border-width solid $color-verisk-black-20;
}

%border-right {
  border-right: $border-width solid $color-verisk-black-20;
}

%border-bottom {
  border-bottom: $border-width solid $color-verisk-black-20;
}

%border-left {
  border-left: $border-width solid $color-verisk-black-20;
}

%border {
  @extend %border-top;
  @extend %border-right;
  @extend %border-bottom;
  @extend %border-left;
}

%border-none {
  border: 0;
}

%border-radius {
  border-radius: $border-radius;
}

%border-top-right-radius {
  border-top-right-radius: $border-radius;
}

%border-top-left-radius {
  border-top-left-radius: $border-radius;
}

%border-bottom-right-radius {
  border-bottom-right-radius: $border-radius;
}

%border-bottom-left-radius {
  border-bottom-left-radius: $border-radius;
}
