@use 'libs/styling/src/lib/variables' as *;
@use '@ag-grid-community/styles' as ag;
@include ag.grid-styles(
  (
    theme: alpine,
    icon-font-family: agGridMaterial,
    header-foreground-color: $color-verisk-black-85,
    header-background-color: $color-verisk-black-5,
    secondary-foreground-color: $color-verisk-black-85,
    foreground-color: $color-verisk-black-85,
    data-color: $color-verisk-black-85,
    alpine-active-color: $color-verisk-action-blue,
    border-color: $color-verisk-black-20,
  )
);

.ag-theme-alpine {
  .ag-group-expanded {
    margin-right: 20px !important;
  }

  .ag-group-contracted {
    margin-right: 20px !important;
  }
}
