@use 'node_modules/shepherd.js/dist/css/shepherd' as *;
@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/buttons' as *;

.shepherd-content {
  color: $color-verisk-ext-blue-1;
  font-size: 1.6rem;

  & .shepherd-header {
    background: white;
    color: inherit;

    & h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }

    & .shepherd-cancel-icon {
      color: inherit;
      font-weight: 300;
      padding-bottom: 0.5rem;
    }
  }

  & .shepherd-footer {
    margin: 1rem;
    margin-right: 0.5rem;
  }

  & .shepherd-text {
    font-size: inherit;
    color: inherit;
  }
}

.shepherd-has-title {
  .shepherd-content {
    & .shepherd-header {
      background: white;
      color: inherit;
    }
  }
}

.shepherd-button {
  @extend .btn;
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow::before {
  background-color: $color-white;
}

.step-count {
  color: $color-maplecroft-medium-grey;
  font-weight: 600;
  font-size: 1.6rem;
  padding-left: 1rem;
}

@media (max-width: 1039px) {
  .shepherd-has-title {
    padding: 2.4rem;

    .shepherd-content {
      .shepherd-header {
        padding: 0;

        h3 {
          font-size: 1.6rem;
        }
      }

      .shepherd-text {
        padding: 1.6rem 0;
        line-height: 2.2rem;
        font-size: 1.4rem;
      }
    }
  }
}
