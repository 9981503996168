@use 'libs/styling/src/lib/variables' as *;
@forward 'libs/styling/src/lib/overrides/shepherd';
@forward 'libs/styling/src/lib/typography';
@forward './style/expand-panel';
@forward 'libs/styling/src/lib/overrides/ag-grid-custom';
@forward './style/ag-grid-customisations';

html {
  font-size: 62.5%;
}

.industry {
  font-size: 1.2rem;
  color: $color-verisk-grey;

  .score-cell-no-score {
    background-color: grey;
  }

  .score-cell-extreme {
    background-color: #ce181f;
  }

  .score-cell-high {
    background-color: #f47721;
  }

  .score-cell-medium {
    background-color: #ffc709;
  }

  .score-cell-low {
    background-color: #d6e040;
  }

  .score-label {
    background-color: unset;
  }

  .form-control {
    font-size: 1.4rem;
  }

  ::-webkit-scrollbar {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    width: $scrollbar-width;
    height: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: $scrollbar-background-color;
    box-shadow: 0 0 0.1rem rgba(255, 255, 255, 50%);
  }
}
