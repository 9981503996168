@use 'libs/styling/src/lib/variables' as *;

.nav {
  height: $nav-link-height;
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 20px;

  > li {
    float: left;
    font-size: $font-size-1;
    color: $breadcrumb-color;

    > a {
      color: $breadcrumb-color;

      &:hover {
        color: $breadcrumb-color-hover;
      }
    }
  }

  > li::after {
    margin-left: 1px;
    margin-right: 3px;
    display: inline-block;
    content: '/';
    color: $breadcrumb-color;
  }

  > li:last-child::after {
    display: none;
  }
}

.pager {
  display: table;
  border-collapse: collapse;

  > .prev {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
  }

  > .page {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $font-size-1;
    color: $page-control-color;
    padding: 0 $padding-1;

    > b {
      color: $page-control-color-bold;
    }
  }

  > .next {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
  }

  .icon {
    display: block;
    cursor: pointer;
  }
}

// @deprecated See component-level styling in
// TabNavBar component in Shared UI Components Module
.sub-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  > li {
    display: inline-block;
    padding: 22px 10px 14px;
    margin: 0 10px;
    border-bottom: 4px solid transparent;

    > a {
      text-transform: uppercase;
      font-size: $font-size-1;
      font-weight: $font-weight-bold;
      color: $color-label-grey;
      cursor: pointer;

      &:hover {
        color: $color-blue;
      }
    }
  }

  > li.active {
    border-bottom-color: $color-blue;

    > a {
      color: $color-black;
    }
  }
}

// @deprecated See component-level styling in
// AppSideAreaLinks component in Core Module
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  > li {
    cursor: pointer;
    margin: 10px 0;

    &:first-child {
      margin: 14px 0 13px;
    }

    > .icon {
      display: block;
      margin: 0 auto;
    }
  }

  > li.active {
    background-color: $color-background-blue;

    > .icon {
      stroke: $color-background-blue;
      fill: $color-blue;
    }
  }
}
