@use 'libs/styling/src/lib/variables' as *;

.marker-cluster-large,
.marker-cluster-medium,
.marker-cluster-small {
  div {
    background-color: $color-maplecroft-bright-blue;
  }
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font:
    12px 'Helvetica Neue',
    Arial,
    Helvetica,
    sans-serif;
}

.marker-cluster span {
  line-height: 30px;
  color: $color-white;
  font-weight: 700;
}
