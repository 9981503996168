@use 'libs/styling/src/lib/variables' as *;

@media (min-width: $breakpoint-xs-lower) {
  [class*='col-'] {
    padding: 0.8rem;
  }

  .row {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}

@media (min-width: $breakpoint-sm-lower) {
  [class*='col-'] {
    padding: 0.8rem;
  }

  .row {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}

@media (min-width: $breakpoint-md-lower) {
  [class*='col-'] {
    padding: 1.2rem;
  }

  .row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

@media (min-width: $breakpoint-lg-lower) {
  [class*='col-'] {
    padding: 1.2rem;
  }

  .row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

@media (min-width: $breakpoint-xl-lower) {
  [class*='col-'] {
    padding: 1.6rem;
  }

  .row {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}

.row-no-padding {
  [class*='col-'] {
    padding: 0;
  }
}

.row-no-margin {
  margin: 0;
}

.nav-link {
  padding: 0.5rem 1rem;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.text-right {
  text-align: right;
}
