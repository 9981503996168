@use 'libs/styling/src/lib/mixins' as mixins;

.grid {
  @include mixins.make-grid;

  > .item {
    @include mixins.make-column($sm: 6, $md: 4, $lg: 3);
  }
}

.grid-with-featured-items {
  @include mixins.make-grid;

  > .item {
    @include mixins.make-column($md: 4);
  }

  > .item:nth-child(1) {
    @include mixins.make-column($md: 8, $lg: 12);
  }

  > .item:nth-child(2) {
    @include mixins.make-column($md: 4, $lg: 6);
  }

  > .item:nth-child(3) {
    @include mixins.make-column($md: 4, $lg: 3);
  }

  > .item:nth-child(4) {
    @include mixins.make-column($md: 4, $lg: 3);
  }

  > .item:nth-child(5) {
    @include mixins.make-column($md: 4, $lg: 8);
  }
}

// Note: Use `.list` for lists, though currently no extra style required
