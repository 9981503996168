@use 'libs/styling/src/lib/overrides/angular-material-deeppurple' as *;
@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/placeholders/typography' as *;
@use 'libs/styling/src/lib/placeholders/border' as *;

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: $z-index-dropdown !important;
}

.cdk-overlay-pane {
  width: 300px !important;
  height: min-content;
  max-height: 600px !important;
  margin-top: $margin-1;
}

.mat-mdc-autocomplete-panel {
  height: min-content;
  max-height: 600px !important;
  padding: $padding-1 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field,
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-form-field-infix {
  min-height: auto !important;
  border-radius: $border-radius !important;
  border: 1px solid $color-verisk-black-20 !important;
  background-color: $color-white !important;
  padding: 1rem 3.2rem !important;
  font-size: 1.4rem;
  height: 3.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:hover,
  &:focus-within {
    border-color: $color-verisk-action-blue !important;
    color: $color-verisk-black-85 !important;
    background-color: $color-white !important;
    box-shadow: none !important;
  }
}

.mat-mdc-form-field-input-control {
  letter-spacing: normal !important;
}

.mat-mdc-form-field-subscript-wrapper,
.mdc-line-ripple {
  display: none !important;
}

.mat-mdc-input-element {
  border: none !important;
}

.search-input-container {
  width: 100%;
  padding: 0 1rem 0 3.2rem;
  align-items: center;
}

.mat-mdc-optgroup-label {
  @extend %lh-21;
  @extend %border-bottom;

  font-size: $font-size-2 !important;
  font-weight: $font-weight-medium !important;
  color: $color-verisk-ext-blue-1 !important;
  margin: 0;
  padding: $padding-1 $padding-1 $padding-1 0 !important;
  display: flex !important;
  align-items: center;
  height: 4rem !important;
  line-height: 2.1rem !important;

  &:first-of-type {
    padding-top: 0 !important;
  }
}

.mat-mdc-option {
  padding: 0 $padding-1 !important;
  height: 3.2rem !important;
  min-height: auto !important;
  line-height: 2.1rem !important;
  letter-spacing: normal !important;

  &:first-of-type {
    margin-top: 0.8rem !important;
  }
}

.mdc-list-item__primary-text {
  font-size: $font-size-2 !important;
  line-height: 2.1rem !important;
}

.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
  padding-left: $padding-1;
}
