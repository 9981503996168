@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/mixins' as mixins;

/**
  Style overrides based on current location
*/

// @deprecated The entire approach to using this
// kind of CSS override based on current location,
// has been replaced with a more declarative easily
// understandable Angular approach using
// dynamically classes and component inputs
// see AppComponent and it"s template (as well as
// AppSideAreaComponent and AppTopAreaComponent

// Home/splash page theme
body[data-url='/'] {
  .header-area {
    background-color: $color-verisk-ext-blue-1;
  }

  .side-area {
    display: none;
  }

  .main-area {
    padding-left: 0;
  }
}

body {
  background-color: $color-white;
}

/**
  Style overrides based on screen size
  (currently uses bootstrap breakpoint mixins)
*/
@include mixins.media-breakpoint-up(lg) {
  // First card in a featured grid should have larger headine
  .grid-with-featured-items > .item:nth-child(1) > .card > .content > .extract > h3 {
    font-size: $font-size-7;
  }
}

.no-size {
  height: 0;
  width: 0;
  padding: 0;
  border: 0;
}

*:focus-visible {
  outline: none;
}
