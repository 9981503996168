@use 'libs/styling/src/lib/variables' as *;
@use 'libs/styling/src/lib/placeholders/typography' as *;

html,
body {
  font-weight: $font-weight-regular;
  font-family: $font-family-1;
  color: $color-verisk-black-85;
  line-height: 2.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: $font-family-1;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-medium;
  color: $color-verisk-ext-blue-1;
}

h2,
h3,
h4,
h5 {
  &.h-sup {
    font-weight: $font-weight-regular;
    color: $color-verisk-black-85;
  }
}

h5 {
  &.h-sup.bold {
    font-weight: bold;
  }
}

h1,
h2,
h3 {
  &.light {
    color: $color-maplecroft-medium-grey;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

h1 {
  font-size: $font-size-7;

  @extend %lh-28;

  margin: 0;

  &.display {
    font-size: 5.5rem;
    line-height: 6.399rem;
  }
}

h2 {
  font-size: $font-size-5;

  @extend %lh-24;

  margin: 0;
}

h3 {
  font-size: $font-size-4;

  @extend %lh-21;

  margin: 0;
}

h4 {
  font-size: $font-size-3;

  @extend %lh-19;
}

h5 {
  font-size: $font-size-2;

  @extend %lh-16;

  margin: 0;
}

h6 {
  font-size: $font-size-1;

  @extend %lh-28;

  margin: 0;
}

p {
  font-size: $font-size-2;
  line-height: 2.2rem;
  font-weight: $font-weight-regular;
  margin: 0 0 2 * $font-size-2;
  color: $color-verisk-black-85;
}

p.large {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: $font-weight-light;
  margin: 2rem 0;
}

p.small {
  font-size: 1.2rem;
}

p.x-small {
  font-size: 1.1rem;
}

.date {
  margin-top: 20px;
  color: #54585a;
}

.caption {
  @extend %caption;
}

p.small,
span.small {
  font-size: $font-size-1;
  line-height: 1.7rem;
}

p.light {
  color: $color-maplecroft-medium-grey;
}

.summary {
  text-transform: uppercase;
  font-size: $font-size-1;
  font-weight: $font-weight-bold;
}

a {
  color: $color-verisk-action-blue;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $color-verisk-action-blue;
    text-decoration: none;
    filter: brightness(90%);
  }

  &.not-text-link {
    color: unset;
    text-decoration: unset;

    &:hover {
      color: unset;
      text-decoration: unset;
      filter: unset;
    }
  }

  &:not([href]):not([class]) {
    color: $color-verisk-action-blue;
    text-decoration: underline;

    &:hover {
      color: $color-verisk-action-blue;
      text-decoration: none;
      filter: brightness(90%);
    }
  }

  &.disabled-link {
    color: $color-verisk-black-15;
    pointer-events: none;
    text-decoration: none;
  }
}

p + h3,
p + h4,
p + h5 {
  margin-top: 2.8rem;
}

h1 + h2,
h2 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
  margin-top: 0.5rem;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p {
  margin-top: 0.8rem;
}

h3 + p.small,
h4 + p.small,
h5 + p.small {
  margin-top: 0.8rem;
}

.item-paragraph ol,
.item-paragraph ul {
  padding-left: 15px;

  li {
    padding-bottom: 0.5rem;
  }
}

.tooltip {
  font-family: $font-family-1;
  font-weight: $font-weight-regular;
  font-size: $font-size-1;
  z-index: $z-index-tooltip;

  .tooltip-inner {
    background-color: $color-verisk-ext-blue-1;
    color: $color-white;
  }
}

.bs-tooltip-right .arrow::before {
  border-right-color: $color-verisk-ext-blue-1;
}

.bs-tooltip-left .arrow::before {
  border-left-color: $color-verisk-ext-blue-1;
}

.bs-tooltip-top .arrow::before {
  border-top-color: $color-verisk-ext-blue-1;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $color-verisk-ext-blue-1;
}

.lh-16 {
  line-height: 1.6rem;
}

.lh-19 {
  line-height: 1.9rem;
}

.lh-21 {
  line-height: 2.1rem;
}

.lh-22 {
  line-height: 2.2rem;
}

.lh-24 {
  line-height: 2.4rem;
}

.lh-26 {
  line-height: 2.6rem;
}

.lh-28 {
  line-height: 2.8rem;
}

.lh-30 {
  line-height: 3rem;
}

.lh-34 {
  line-height: 3.4rem;
}

.lh-46 {
  line-height: 4.6rem;
}
