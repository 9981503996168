@use 'libs/styling/src/lib/variables' as *;

%inline {
  display: inline;
}

%flex {
  display: flex;
}

%block {
  display: block;
}

%inline-flex {
  display: inline-flex;
}

%inline-block {
  display: inline-block;
}

%grid {
  display: grid;
}

%none {
  display: none;
}

%flex-1 {
  // e.g. flex: 1;
  @extend %flex-shrink;
  @extend %flex-grow;
  @extend %flex-basis-0;
}

%align-start {
  align-items: start;
}

%align-center {
  align-items: center;
}

%align-end {
  align-items: flex-end;
}

%justify-start {
  justify-content: start;
}

%justify-center {
  justify-content: center;
}

%justify-end {
  justify-content: flex-end;
}

%justify-between {
  justify-content: space-between;
}

%justify-around {
  justify-content: space-around;
}

%flex-grow {
  flex-grow: 1;
}

%flex-shrink {
  flex-shrink: 1;
}

%flex-basis-0 {
  flex-basis: 0;
}

%flex-wrap {
  flex-wrap: wrap;
}

%flex-start-center {
  @extend %flex;
  @extend %justify-start;
  @extend %align-center;
}

%flex-center-center {
  @extend %flex;
  @extend %justify-center;
  @extend %align-center;
}

%flex-center-end {
  @extend %flex;
  @extend %justify-center;
  @extend %align-end;
}

%flex-end-end {
  @extend %flex;
  @extend %justify-end;
  @extend %align-end;
}

%flex-row {
  @extend %flex;

  flex-direction: row;
}

%flex-column {
  @extend %flex;

  flex-direction: column;
}

%flex-grow {
  flex-grow: 1;
}

%padding-right-1 {
  padding-right: $spacing-1;
}

%padding-left-1 {
  padding-left: $padding-1;
}

%padding {
  padding: calc(#{$padding-4} / 2);
}

@media (min-width: $breakpoint-xs-lower) {
  %padding {
    padding: calc(#{$padding-2} / 2);
  }
}

@media (min-width: $breakpoint-sm-lower) {
  %padding {
    padding: calc(#{$padding-2} / 2);
  }
}

@media (min-width: $breakpoint-md-lower) {
  %padding {
    padding: calc(#{$padding-3} / 2);
  }
}

@media (min-width: $breakpoint-lg-lower) {
  %padding {
    padding: calc(#{$padding-3} / 2);
  }
}

@media (min-width: $breakpoint-xl-lower) {
  %padding {
    padding: calc(#{$padding-4} / 2);
  }
}

%margin-0 {
  margin: 0;
}

%margin-bottom-0 {
  margin-bottom: 0;
}

%margin-left-auto {
  margin-left: auto;
}

%margin-left-1 {
  margin-left: $margin-1;
}

%margin-right-1 {
  margin-right: $margin-1;
}

%padding-bottom-2 {
  padding-bottom: $padding-2;
}

%padding-2 {
  padding: calc(#{$padding-4});
}

@media (min-width: $breakpoint-xs-lower) {
  %padding-2 {
    padding: calc(#{$padding-2});
  }
}

@media (min-width: $breakpoint-sm-lower) {
  %padding-2 {
    padding: calc(#{$padding-2});
  }
}

@media (min-width: $breakpoint-md-lower) {
  %padding-2 {
    padding: calc(#{$padding-3});
  }
}

@media (min-width: $breakpoint-lg-lower) {
  %padding-2 {
    padding: calc(#{$padding-3});
  }
}

@media (min-width: $breakpoint-xl-lower) {
  %padding-2 {
    padding: calc(#{$padding-4});
  }
}

%margin {
  margin: calc(#{$margin-4} / 2);
}

@media (min-width: $breakpoint-xs-lower) {
  %margin {
    margin: calc(#{$margin-2} / 2);
  }
}

@media (min-width: $breakpoint-sm-lower) {
  %margin {
    margin: calc(#{$margin-2} / 2);
  }
}

@media (min-width: $breakpoint-md-lower) {
  %margin {
    margin: calc(#{$margin-3} / 2);
  }
}

@media (min-width: $breakpoint-lg-lower) {
  %margin {
    margin: calc(#{$margin-3} / 2);
  }
}

@media (min-width: $breakpoint-xl-lower) {
  %margin {
    margin: calc(#{$margin-4} / 2);
  }
}

%margin-2 {
  margin: calc(#{$margin-4});
}

@media (min-width: $breakpoint-xs-lower) {
  %margin-2 {
    margin: calc(#{$margin-2});
  }
}

@media (min-width: $breakpoint-sm-lower) {
  %margin-2 {
    margin: calc(#{$margin-2});
  }
}

@media (min-width: $breakpoint-md-lower) {
  %margin-2 {
    margin: calc(#{$margin-3});
  }
}

@media (min-width: $breakpoint-lg-lower) {
  %margin-2 {
    margin: calc(#{$margin-3});
  }
}

@media (min-width: $breakpoint-xl-lower) {
  %margin-2 {
    margin: calc(#{$margin-4});
  }
}

%static {
  position: static;
}

%relative {
  position: relative;
}

%absolute {
  position: absolute;
}

%fixed {
  position: fixed;
}

%vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

%horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

%center {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

%text-center {
  text-align: center;
}

%text-left {
  text-align: left;
}

%content {
  content: '';
}

// Triangles made from borders. You can set the size and color in a component
// using the css variables --triangle-height and --triangle-color
%triangle-up {
  height: 0px;
  width: 0px;
  background: transparent;
  border: var(--triangle-height) solid var(--triangle-color);
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

%triangle-down {
  height: 0px;
  width: 0px;
  background: transparent;
  border: var(--triangle-height) solid var(--triangle-color);
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

%triangle-left {
  height: 0px;
  width: 0px;
  background: transparent;
  border: var(--triangle-height) solid var(--triangle-color);
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

%triangle-right {
  height: 0px;
  width: 0px;
  background: transparent;
  border: var(--triangle-height) solid var(--triangle-color);
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}

%circle {
  border-radius: 50%;
}

%top-0 {
  top: 0;
}

%right-0 {
  right: 0;
}

%left-0 {
  left: 0;
}

%bottom-0 {
  bottom: 0;
}

%height-100 {
  height: 100%;
}

%width-100 {
  width: 100%;
}

%hidden {
  visibility: hidden;
}
