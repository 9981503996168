@use 'libs/styling/src/lib/variables' as *;

.attribution {
  display: block;
  text-align: right;
  padding: 0 0 0 8px;
  border-radius: 2px;
  transition: all 0.2s ease;

  > .caption {
    visibility: hidden;
    display: inline-block;
    vertical-align: middle;
    color: $color-white;

    a,
    a:hover {
      color: $color-verisk-action-blue-10;
    }
  }

  > .icon {
    margin: 0;
    vertical-align: middle;
    stroke: transparent;
    fill: $color-label-grey;
  }

  &:hover {
    background-color: rgb(0 0 0 / 50%);

    > .caption {
      visibility: visible;
    }

    > .icon {
      fill: $color-white;
    }
  }
}
