@use 'libs/styling/src/lib/variables' as *;
// @deprecated Use of this global style in the app has been deprecated
// see component-level styling in AppTopAreaComponent in Core Module
.header-area {
  background-color: $color-blue;
  color: $color-white;
  width: 100%;
  height: 60px;
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
}

// @deprecated Use of this global style in the app has been deprecated
// see component-level styling in AppSideAreaComponent in Core Module
.side-area {
  background-color: $color-white;
  width: 60px;
  height: 100%;
  padding-top: 60px;
  border-right: 1px solid $color-maplecroft-light-grey;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
}

// @deprecated Use of this global style in the app has been deprecated
// see component-level styling in RoutingTabNavBarComponent in Shared UI Components Module
.top-area {
  border-bottom: 1px solid $color-maplecroft-light-grey;
  width: 100%;
  padding-left: 60px;
  height: 60px;
  margin-top: 60px;
  box-sizing: border-box;
}

// @deprecated Use of this global style in the app has been deprecated
// see component-level styling in AppComponent i.p. see
// classes "mc-main-area" and "mc-side-navigation-shown"
// "content" class has been replaced with component-level styling in
// Feature components which are routed into the AppComponent router-outlet.
// E.g. HomeComponent (directly used for splash screen)or MainRouterContentComponent
// in Shared UI Components Module which is used in all of: Country Risk, Commodity Risk,
// Document LibraryComponent etc.
// For consistency they use an "mc-content" class on their main wrapper.
.main-area {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  padding-left: 60px;
  box-sizing: border-box;

  > .content {
    height: 100%;
    box-sizing: border-box;
    overflow-y: visible;
  }
}

html,
body {
  background-color: $color-white;
  height: 100%;
}

// @deprecated see above
.top-area + .main-area {
  padding-top: 120px;
}
